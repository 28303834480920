<template>
  <div class="flex flex-col gap-8 mt-16">
    <!-- 3.1-2-4 (8.2)-->
    <div class="flex gap-8">
      <div class="w-[18rem] shrink-0">
        <input-text
          name="individual.businessInfo.zipCode"
          size="lg"
          variant="outline"
          searchable
          :placeholder="
            $t('studio.business_bank_setting.individual.info_global_adress_no_place_holder')
          "
          :rules="{
            required: $t('studio.business_bank_setting.individual.info_global_adress_no_msg1'),
            number: true,
            max_length: {
              length: 50,
              message: $t('studio.common.def_key.exceed', { length: 50 })
            }
          }"
          :countable="false"
          :pattern="NO_SPECIAL_CHAR_REGEX"
          maxLength="50"
          :disabled="disabled"
          @search="emits('search')"
        />
      </div>
      <!-- 3.1-2-4 (8.4)-->
      <div class="w-[calc(100%-18rem-8px)]">
        <input-text
          name="individual.businessInfo.address"
          :placeholder="
            $t('studio.business_bank_setting.individual.info_global_o_adress_place_holder')
          "
          :rules="{
            required: $t('studio.business_bank_setting.individual.info_global_o_adress_msg1'),
            max_length: {
              length: 50,
              message: $t('studio.common.def_key.exceed', { length: 50 })
            }
          }"
          :disabled="disabled"
        />
      </div>
    </div>
    <!-- 3.1-2-4 (8.5)-->
    <input-text
      name="individual.businessInfo.fullAddress"
      :placeholder="
        $t('studio.business_bank_setting.individual.info_global_o_adress_detail_place_holder')
      "
      :rules="{
        required: $t('studio.business_bank_setting.individual.info_global_o_adress_detail_msg1'),
        max_length: {
          length: 50,
          message: $t('studio.common.def_key.exceed', { length: 50 })
        }
      }"
      :disabled="disabled"
    />
  </div>
</template>

<script setup lang="ts">
import InputText from '@/components/validation/input-text.vue';
import { NO_SPECIAL_CHAR_REGEX } from '@/constants/regex.const';

defineProps<{
  disabled?: boolean;
}>();

const emits = defineEmits(['search']);
</script>
